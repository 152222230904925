import React from "react";
import Col from "react-bootstrap/Col";
import { v4 as uuidv4 } from "uuid";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row } from "react-bootstrap";

const OurServicesSection = ({ title, services }) => {
  return (
    <section className="py-5 py-lg-8">
      <Container className="">
        <Row className="">
          <Col className="text-center pb-6">
            <h2>{title || "OUR SERVICES"}</h2>
          </Col>
        </Row>
        {services && services.length > 0 && (
          <Row>
            {services.map((serviceItem, i) => (
              <Col
                className={i + 1 !== services.length ? "mb-5 mb-xl-0" : ""}
                lg={6}
                xl={4}
                key={uuidv4()}
              >
                <Link
                  className="black-link fs-4 inter-bold"
                  to={serviceItem.servicesPostFields?.serviceUrl?.url}
                >
                  <div className="bg-grey ">
                    <GatsbyImage
                      className="service-card-image"
                      image={
                        serviceItem.featuredImage?.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={serviceItem.featuredImage?.node?.altText}
                    />
                    <div className="p-4 text-center">
                      <h3 className="fs-2 ">{serviceItem.title}</h3>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </section>
  );
};

export default OurServicesSection;
