import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import Badges from "../../components/badges";
import BumperBanner from "../../components/bumber-banner";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import ImageSideContainer from "../../components/image-side-container";
import { Button } from "react-bootstrap";
import MotForm from "../../components/mot-form";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const BodyshopSussexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: file(relativePath: { eq: "icon.png" }) {
        publicURL
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "bodyshop-sussex" }) {
        slug
        bodyshopSussex {
          bodyshopSussexBanner {
            bodyshopSussexBannerHeading
            bodyshopSussexBannerBackgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          bodyshopSussexContentSection {
            bodyshopSussexContentHeading
            bodyshopSussexContentText
            bodyshopSussexContentButton {
              title
              target
              url
            }
          }
          bodyshopSussexLogoSlider {
            bodyshopSussexLogoSliderHeading
            bodyshopSussexLogoSliderNotice
            bodyshopSussexLogos {
              nodes {
                ... on WpVehicleLogo {
                  vehicleLogoFields {
                    logoUrl
                    logoImage {
                      node {
                        altText
                        localFile {
                          publicURL
                          childImageSharp {
                            gatsbyImageData(
                              formats: [AUTO, WEBP]
                              quality: 100
                              transformOptions: {
                                cropFocus: CENTER
                                fit: CONTAIN
                              }
                              layout: CONSTRAINED
                              placeholder: BLURRED
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          bodyshopSussexLeftImageRightContent {
            bodyshopSussexRightContentHeading
            bodyshopSussexRightContentText
            bodyshopSussexLeftImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            bodyshopSussexRightContentButton {
              title
              target
              url
            }
          }
          bodyshopSussexBgImageOverlayContent {
            bodyshopSussexOverlayLink {
              title
              target
              url
            }
            bodyshopSussexBgImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          bodyshopSussexBgImageOverlayContentRepeat {
            bodyshopSussexOverlayHeading
            bodyshopSussexOverlayContent
            bodyshopSussexOverlayLink {
              title
              target
              url
            }
            bodyshopSussexBgImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          bodyshopSussexForm {
            bodyshopSussexFormHeading
          }
          bodyshopSussexBumper {
            bodyshopSussexBumperHeading
            bodyshopSussexBumperNumbers {
              accidentRepairBumperNumberHeading
              accidentRepairBumperNumberPostfix
              accidentRepairBumperNumberPrefix
              accidentRepairBumperNumberText
            }
            bodyshopSussexBumperButton {
              title
              target
              url
            }
          }
          bodyshopSussexReviews {
            bodyshopSussexReviewsHeading
          }
          bodyshopSussexRightImageLeftContent {
            bodyshopSussexLeftContentHeading
            bodyshopSussexLeftContentText
            bodyshopSussexLeftContentButton {
              title
              target
              url
            }
            bodyshopSussexRightImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          bodyshopSussexRightImageLeftContentRepeat {
            bodyshopSussexLeftContentHeading
            bodyshopSussexLeftContentText
            bodyshopSussexRightImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            bodyshopSussexLeftContentButton {
              target
              title
              url
            }
          }
          bodyshopSussexServicesCards {
            bodyshopSussexServicesCardsHeading
            bodyshopSussexCards {
              nodes {
                ... on WpService {
                  title
                  servicesPostFields {
                    serviceUrl {
                      target
                      title
                      url
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: [AUTO, WEBP]
                            quality: 100
                            transformOptions: {
                              cropFocus: CENTER
                              fit: CONTAIN
                            }
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          bodyshopSussexVideo {
            bodyshopSussexVideo {
              node {
                altText
                mediaItemUrl
              }
            }
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFields, bodyshopSussex, slug },
  } = data;

  const {
    bodyshopSussexBanner,
    bodyshopSussexContentSection,
    bodyshopSussexLogoSlider,
    bodyshopSussexLeftImageRightContent,
    bodyshopSussexBgImageOverlayContent,
    bodyshopSussexBgImageOverlayContentRepeat,
    bodyshopSussexForm,
    bodyshopSussexBumper,
    bodyshopSussexReviews,
    bodyshopSussexRightImageLeftContent,
    bodyshopSussexRightImageLeftContentRepeat,
    bodyshopSussexServicesCards,
    bodyshopSussexVideo,
  } = bodyshopSussex;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
    mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    description: `${seoFields?.metaDescription}`,
    logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
    name: "ECF Car Care",
    url: "https://www.ecfcarcare.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
      image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
      name: "ECF Car Care",
      url: "https://www.ecfcarcare.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },

    review: {
      "@type": "Review",
      url: "https://www.ecfcarcare.co.uk/reviews",
      datePublished: `${data.reviews.reviewDate}`,
      reviewBody: `${data.reviews.reviewContent}`,
      author: {
        "@type": "Person",
        name: `${data.reviews.reviewAuthor}`,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "ECF Car Care",
        sameAs: "https://www.ecfcarcare.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "What We Do",
        item: {
          url: `${siteUrl}/what-we-do`,
          id: `${siteUrl}/what-we-do`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Bodyshop Sussex",
        item: {
          url: `${siteUrl}/what-we-do/bodyshop-sussex`,
          id: `${siteUrl}/what-we-do/bodyshop-sussex`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {bodyshopSussexBanner && (
          <section>
            <ServiceHero
              title={bodyshopSussexBanner.bodyshopSussexBannerHeading}
              backgroundImage={
                bodyshopSussexBanner.bodyshopSussexBannerBackgroundImage.node
                  ?.localFile.childImageSharp.gatsbyImageData
              }
              imgAlt={
                bodyshopSussexBanner.bodyshopSussexBannerBackgroundImage.node
                  ?.altText
              }
            />
          </section>
        )}
        {bodyshopSussexContentSection &&
          !checkPropertiesForNull(bodyshopSussexContentSection, [
            "bodyshopSussexContentHeading",
          ]) && (
            <section className="pt-5  pt-lg-8 position-relative">
              <Container>
                <Row>
                  <Col className="position-relative">
                    <h2 className=" text-center pb-5">
                      {
                        bodyshopSussexContentSection.bodyshopSussexContentHeading
                      }
                    </h2>

                    <SafeHtmlParser
                      htmlContent={
                        bodyshopSussexContentSection?.bodyshopSussexContentText
                      }
                    />
                    {bodyshopSussexContentSection.bodyshopSussexContentButton &&
                      bodyshopSussexContentSection.bodyshopSussexContentButton
                        .url && (
                        <Row>
                          <Col className="text-center text-md-start">
                            <Button
                              style={{ borderRadius: "0px" }}
                              variant="secondary"
                              as={Link}
                              to={
                                bodyshopSussexContentSection
                                  .bodyshopSussexContentButton.url
                              }
                              className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
                              target={
                                bodyshopSussexContentSection
                                  .bodyshopSussexContentButton.target ?? "_self"
                              }
                            >
                              {
                                bodyshopSussexContentSection
                                  .bodyshopSussexContentButton.title
                              }
                            </Button>
                          </Col>
                        </Row>
                      )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}

        {bodyshopSussexLogoSlider && (
          <Badges
            title={bodyshopSussexLogoSlider.bodyshopSussexLogoSliderHeading}
            text={bodyshopSussexLogoSlider.bodyshopSussexLogoSliderNotice}
            logos={bodyshopSussexLogoSlider.bodyshopSussexLogos?.nodes}
          />
        )}

        {bodyshopSussexLeftImageRightContent &&
          !checkPropertiesForNull(bodyshopSussexLeftImageRightContent, [
            "bodyshopSussexRightContentHeading",
          ]) && (
            <div className="py-3">
              <ImageSideContainer
                image={
                  bodyshopSussexLeftImageRightContent.bodyshopSussexLeftImage
                    .node?.localFile.childImageSharp.gatsbyImageData
                }
                imageAlt={
                  bodyshopSussexLeftImageRightContent.bodyshopSussexLeftImage
                    .node?.altText
                }
                title={
                  bodyshopSussexLeftImageRightContent.bodyshopSussexRightContentHeading
                }
                order="first"
                imagePadding=" pb-5 pb-lg-0    "
                text={
                  bodyshopSussexLeftImageRightContent.bodyshopSussexRightContentText
                }
              />
            </div>
          )}
        {bodyshopSussexRightImageLeftContent &&
          !checkPropertiesForNull(bodyshopSussexRightImageLeftContent, [
            "bodyshopSussexRightContentHeading",
          ]) && (
            <div className="py-3">
              <ImageSideContainer
                image={
                  bodyshopSussexRightImageLeftContent.bodyshopSussexRightImage
                    .node?.localFile.childImageSharp.gatsbyImageData
                }
                imageAlt={
                  bodyshopSussexRightImageLeftContent.bodyshopSussexRightImage
                    .node?.altText
                }
                title={
                  bodyshopSussexRightImageLeftContent.bodyshopSussexLeftContentHeading
                }
                order="last"
                imagePadding="pb-5 pb-lg-0    "
                text={
                  bodyshopSussexRightImageLeftContent.bodyshopSussexLeftContentText
                }
                ctaButton={
                  bodyshopSussexRightImageLeftContent.bodyshopSussexLeftContentButton
                }
              />
            </div>
          )}

        {bodyshopSussexBgImageOverlayContent &&
          !checkPropertiesForNull(bodyshopSussexBgImageOverlayContent, [
            "bodyshopSussexOverlayLink",
          ]) && (
            <section>
              {" "}
              <BgImage
                className="w-100 "
                alt=""
                image={getImage(
                  bodyshopSussexBgImageOverlayContent.bodyshopSussexBgImage.node
                    ?.localFile.childImageSharp.gatsbyImageData
                )}
              >
                <Container
                  style={{ zIndex: 2 }}
                  className="h-100 py-5 pt-lg-10"
                >
                  <Row className="align-items-end h-100 py-5 pt-lg-10">
                    <Col className="text-center pt-lg-5 mx-auto" lg={8}>
                      <a
                        rel="noreferrer"
                        href={
                          bodyshopSussexBgImageOverlayContent
                            .bodyshopSussexOverlayLink.url
                        }
                        className="white-link inter-bold fs-2"
                        target={
                          bodyshopSussexBgImageOverlayContent
                            .bodyshopSussexOverlayLink.target ?? "_blank"
                        }
                      >
                        {
                          bodyshopSussexBgImageOverlayContent
                            .bodyshopSussexOverlayLink.title
                        }
                      </a>
                    </Col>
                  </Row>
                </Container>
              </BgImage>
            </section>
          )}
        <section>
          <Reviews
            title={bodyshopSussexReviews?.bodyshopSussexReviewsHeading}
          />
        </section>
        {bodyshopSussexBgImageOverlayContentRepeat &&
          !checkPropertiesForNull(bodyshopSussexBgImageOverlayContentRepeat, [
            "bodyshopSussexOverlayHeading",
          ]) && (
            <section>
              {" "}
              <BgImage
                style={{ backgroundPosition: "top" }}
                className=""
                alt=""
                image={getImage(
                  bodyshopSussexBgImageOverlayContentRepeat
                    .bodyshopSussexBgImage.node?.localFile.childImageSharp
                    .gatsbyImageData
                )}
              >
                <Container
                  style={{ zIndex: 2 }}
                  className="position-relative py-xl-10 py-5 "
                >
                  <Row className="justify-content-end">
                    <Col lg={7} className="">
                      <div className="position-relative  p-5">
                        <div
                          style={{
                            zIndex: 1,
                            opacity: 0.8,
                            backgroundColor: "white",
                          }}
                          className="position-absolute h-100 w-100 start-0 bottom-0"
                        ></div>
                        <div
                          className="position-relative"
                          style={{ zIndex: 2 }}
                        >
                          <h2 className=" pb-4">
                            {
                              bodyshopSussexBgImageOverlayContentRepeat.bodyshopSussexOverlayHeading
                            }
                          </h2>

                          <SafeHtmlParser
                            htmlContent={
                              bodyshopSussexBgImageOverlayContentRepeat?.bodyshopSussexOverlayContent
                            }
                          />
                          {bodyshopSussexBgImageOverlayContentRepeat.bodyshopSussexOverlayLink &&
                            bodyshopSussexBgImageOverlayContentRepeat
                              .bodyshopSussexOverlayLink.url && (
                              <Row>
                                <Col className="text-center text-md-start">
                                  <Button
                                    style={{ borderRadius: "0px" }}
                                    variant="secondary"
                                    as={Link}
                                    to={
                                      bodyshopSussexBgImageOverlayContentRepeat
                                        .bodyshopSussexOverlayLink.url
                                    }
                                    className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
                                    target={
                                      bodyshopSussexBgImageOverlayContentRepeat
                                        .bodyshopSussexOverlayLink.target ??
                                      "_self"
                                    }
                                  >
                                    {
                                      bodyshopSussexBgImageOverlayContentRepeat
                                        .bodyshopSussexOverlayLink.title
                                    }
                                  </Button>
                                </Col>
                              </Row>
                            )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </BgImage>
            </section>
          )}
        {bodyshopSussexRightImageLeftContentRepeat &&
          !checkPropertiesForNull(bodyshopSussexRightImageLeftContentRepeat, [
            "bodyshopSussexLeftContentHeading",
          ]) && (
            <div className="py-5 py-lg-8">
              <ImageSideContainer
                image={
                  bodyshopSussexRightImageLeftContentRepeat
                    .bodyshopSussexRightImage.node?.localFile.childImageSharp
                    .gatsbyImageData
                }
                imageAlt={
                  bodyshopSussexRightImageLeftContentRepeat
                    .bodyshopSussexRightImage.node?.altText
                }
                title={
                  bodyshopSussexRightImageLeftContentRepeat.bodyshopSussexLeftContentHeading
                }
                order="last"
                imagePadding="pb-5 pb-lg-0    "
                text={
                  bodyshopSussexRightImageLeftContentRepeat.bodyshopSussexLeftContentText
                }
                ctaButton={
                  bodyshopSussexRightImageLeftContentRepeat.bodyshopSussexLeftContentButton
                }
              />
            </div>
          )}
        {bodyshopSussexVideo &&
          !checkPropertiesForNull(bodyshopSussexVideo, [
            "bodyshopSussexVideo",
          ]) && (
            <section className="bg-white">
              <Container className="pb-5 pb-lg-7">
                <Row>
                  <Col>
                    <div>
                      <video style={{ width: "100%" }} controls>
                        <source
                          src={
                            bodyshopSussexVideo.bodyshopSussexVideo.node
                              ?.mediaItemUrl
                          }
                          type="video/mp4"
                        />
                        Sorry, your browser doesn't support videos.
                      </video>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}

        {bodyshopSussexBumper &&
          !checkPropertiesForNull(bodyshopSussexBumper, [
            "bodyshopSussexBumperHeading",
            "bodyshopSussexBumperNumbers",
          ]) && (
            <BumperBanner
              title={bodyshopSussexBumper.bodyshopSussexBumperHeading}
              items={bodyshopSussexBumper.bodyshopSussexBumperNumbers}
              ctaButton={bodyshopSussexBumper.bodyshopSussexBumperButton}
            />
          )}
        <section id="book" className="pb-5 pb-lg-0 pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6}>
                <h2 className="text-center pb-4">
                  {bodyshopSussexForm?.bodyshopSussexFormHeading ||
                    "BOOK YOUR BODYSHOP APPOINTMENT"}
                </h2>
                <div
                  style={{ background: "#F9F9F9", borderRadius: "18px" }}
                  className="p-md-5 p-4"
                >
                  <MotForm page="Bodyshop Sussex" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {bodyshopSussexServicesCards &&
          !checkPropertiesForNull(bodyshopSussexServicesCards, [
            "bodyshopSussexServicesCardsHeading",
            "bodyshopSussexCards",
          ]) && (
            <OurServicesSection
              title={
                bodyshopSussexServicesCards.bodyshopSussexServicesCardsHeading
              }
              services={bodyshopSussexServicesCards.bodyshopSussexCards?.nodes}
            />
          )}
      </Layout>
    </>
  );
};

export default BodyshopSussexPage;
